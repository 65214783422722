import { envDef, firebaseConfig } from 'src/environments/env.default';

export const environment = {
    ...envDef,
    qa: true,
    prefix: 'convenio-qa',
    version: '1.0.63',
    urlAPI: 'https://convenio.qa.api.gestor.ladder.dev.br',
    urlBase: 'qa.convenio.app',
    CrossDomainStorageOrigin: 'https://convenio.qa.api.gestor.ladder.dev.br',
    firebase: firebaseConfig
};
